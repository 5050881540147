angular.module('app.controllers').controller('MerklistePrintCtrl', [
  '$scope',
  '$rootScope',
  '$location',
  'BookmarkService',
  function(self,
  root,
  $location,
  BookmarkService) {
    if (root.savedArticles && root.savedArticles.length === 0) {
      $location.path("/");
    }
    root.printMode = true;
    return self.print = function() {
      return window.print();
    };
  }
]);
